import request from '@/utils/request'
import store from '@/store'

export const staffInfoList = (data) => {
  return request({
    url: '/staffInfo/list',
    params: {
      ...data
    }
  })
}
export const approvedOrRejected = (data) => {
  return request({
    url: '/staffInfo/approvedOrRejected',
    method: 'POST',
    data
  })
}
export const attendanceDetail = (data) => {
  return request({
    url: '/staffInfo/attendanceDetail',
    params: {
      ...data
    }
  })
}
export const saveEnvelope = (data) => {
  return request({
    url: '/internAttendanceSummaryPay/saveEnvelope',
    method: 'POST',
    data
  })
}
const url = process.env['VUE_APP_BASE_API']
export const staffInfoExport = (data) => {
  window.location = `${url}/api/iris/staffInfo/export?attendanceDate=${data.attendanceDate}&hfmCode=${data.hfmCode}&orgIds=${data.orgIds}&staffName=${data.staffName}&status=${data.status}&ids=${data.ids}&token=${store.getters.token}`
}

export const staffSumInfoExport = (data) => {
  window.location = `${url}/api/iris/internAttendanceSummaryPay/exportSum?attendanceMonth=${data.attendanceMonth}&hfmCode=${data.hfmCode}&nameEn=${data.nameEn}&status=${data.status}&nation=${data.nation}&payBatch=${data.payBatch}&formalDateStart=${data.formalDateStart}&formalDateEnd=${data.formalDateEnd}&formalStatus=${data.formalStatus}&ids=${data.ids}&token=${store.getters.token}`
}

export const staffSumPayExport = (data) => {
  window.location = `${url}/api/iris/internAttendanceSummaryPay/exportPay?pays=${data}&token=${store.getters.token}`
}
