export const statusList = [
  {
    label: '待提交',
    value: 'ready'
  },
  {
    label: '已审核',
    value: 'approved'
  },
  {
    label: '已驳回',
    value: 'rejected'
  },
  {
    label: '已提交',
    value: 'submit'
  }
]
export const columnConfig = [
  {
    key: 'code',
    name: '编码',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 100 // 默认宽度，像素
  },
  {
    key: 'nameCn',
    name: '中文名',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: null,
    width: 120
  },
  {
    key: 'nameEn',
    name: '英文名',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: null,
    width: 120
  },
  {
    key: 'attendanceDate',
    name: '时间',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: null,
    width: 100
  },
  {
    key: 'status',
    name: '状态',
    dataType: 'string',
    visible: true,
    sortable: false,
    align:'center',
    fixed: null,
    width: 120
  },
  {
    key: 'hfmCode',
    name: 'HFM Code',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: null,
    width: 130
  },
  {
    key: 'brand',
    name: 'Brand',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: null,
    width: 120
  },
  {
    key: 'orgName',
    name: '部门名',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: null,
    width: 150
  },
  {
    key: 'location',
    name: '工作地',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: null,
    width: 120
  },
  {
    key: 'attendanceDays',
    name: '工作天数',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: null,
    width: 100
  },
  {
    key: 'reportName',
    name: '确认人名字',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: null,
    width: 120
  },
  {
    key: 'attendanceFirstSendingTime',
    name: '第一次发送时间',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: null,
    width: 130
  },
  {
    key: 'attendanceLastSendingTime',
    name: '最新发送时间',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: null,
    width: 120
  },
  {
    key: 'submitDate',
    name: '提交时间',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: null,
    width: 120
  },
  {
    key: 'approveDate',
    name: 'LM审批时间',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: null,
    width: 120
  }
]
