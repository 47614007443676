<template>
  <div class="attendanceRecord">
    <div class="attendanceRecord__topInput">
      <div class="attendanceRecord__topInput__title">
        <div class="attendanceRecord__topInput__title__leftTitle">考勤记录</div>
        <div class="attendanceRecord__topInput__title__rightButton">
          <div
            class="attendanceRecord__topInput__title__rightButton__reset"
          ></div>
          <el-button type="primary" @click="search">查询</el-button>
        </div>
      </div>
      <!--        1212-->
      <div class="attendanceRecord__topInput__search">
        <el-form
          :inline="true"
          :model="formInline"
          label-position="top"
          class="demo-form-inline minWidthInput"
        >
          <el-form-item label="统计月份">
            <el-date-picker
              v-model="formInline.attendanceDate"
              placeholder="统计月份"
              type="month"
              value-format="yyyy-MM"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="HFM Code">
            <el-select
              v-model="formInline.hfmCode"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in HFMList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属部门">
            <el-cascader
              v-model="formInline.orgIds"
              clearable
              placeholder="选择所属机构"
              :options="orgTreeData"
              :props="{ label: 'name', value: 'id' }"
              filterable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="中/英文名">
            <el-input
              v-model="formInline.staffName"
              placeholder="中/英文名"
            ></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select
              v-model="formInline.status"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="attendanceRecord__content" id="attendanceRecord__content_id">
      <PocTable
        v-loading="loading"
        :data="tableData"
        :max-height="100000000"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        stripe
        :cell-class-name="cellClassName"
        @selection-change="handleSelectionChange"
        @cell-click="rowClick"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
      >
        <!--        <span slot="tip">-->
        <div slot="tip" class="tipTitle">
          <div>
            <el-button @click="approved" role="approved" v-permission>审批</el-button>
            <el-button @click="rejected" role="reject" v-permission>驳回</el-button>
          </div>
          <div>
            <el-button @click="exportAttendance">导出</el-button>
          </div>
        </div>
        <!--        </span>-->
        <el-table-column type="selection" fixed width="50" />
        <el-table-column
          v-for="item in columnConfig"
          v-if="item.visible"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :align="item.align || 'left'"
          :width="item.width"
        >
          <template slot-scope="{row}">
            <div v-if="item.key == 'status'" :class="{'green_box': row[item.key] == '已审核','orange_box': row[item.key] == '待提交','red_box': row[item.key] == '已驳回','blue_box': row[item.key] == '已提交'}">
              {{row[item.key]}}
            </div>
            <div v-else>{{ row[item.key] }}</div>
          </template>
        </el-table-column>
      </PocTable>
    </div>
    <attendanceRecordDetailDialog ref="detailRef" />
  </div>
</template>

<script>
import { statusList, columnConfig } from '@/views/attendanceRecord/Colum.js'
import {
  approvedOrRejected,
  staffInfoExport,
  staffInfoList
} from '@/api/attendance'
import { getTree } from '@/api/org'
import attendanceRecordDetailDialog from '@/views/attendanceRecord/attendanceRecordDetailDialog'
import { getHfmCodeList } from '@/api/organization'
const { Message } = $PCommon
export default {
  name: 'attendanceRecord',
  mixins: [$PCommon.TableMixin],
  components: {
    attendanceRecordDetailDialog
  },
  created() {
    let h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    this.maxHeight = h - 420
    this.queryApi()
    this.getDepartData()
  },
  data() {
    return {
      formInline: {
        attendanceDate: '',
        user: '',
        staffName: '',
        hfmCode: '',
        orgIds: [],
        status: ''
      },
      orgTreeData: [],
      HFMList: [],
      statusList,
      columnConfig,
      loading: false,
      maxHeight: '',
      tableData: [],
      selectList: [],
      selectAll: false
    }
  },
  methods: {
    exportAttendance() {
      const attendanceDate = this.$dayjs(new Date()).format('YYYY-MM')
      const obj = {
        attendanceDate,
        ids: this.selectList.map((item) => item.id).toString(),
        ...this.formInline
      }
      console.log(obj)
      staffInfoExport(obj)
    },
    rowClick(row, column, cell, event) {
      if (column.property === 'hfmCode') {
        this.$refs.detailRef.show({
          id: row.id,
          date: row.attendanceDate
        })
      }
    },
    approved() {
      if (this.selectList.length) {
        const ids = this.selectList.map((item) => item.id)
        const attendanceDate = this.$dayjs(new Date()).format('YYYY-MM')
        console.log(attendanceDate)
        const obj = {
          attendanceDate,
          ids,
          status: 'approved'
        }
        approvedOrRejected(obj).then((res) => {
          if (res && res.code === 200) {
            Message.success('审批成功')
            this.queryApi()
          }
        })
      } else {
        Message.warning('请选择审批项目')
      }
    },
    handleSelectionChange(val) {
      this.selectList = val
    },
    rejected() {
      if (this.selectList.length) {
        const ids = this.selectList.map((item) => item.id)
        const attendanceDate = this.$dayjs(new Date()).format('YYYY-MM')
        const obj = {
          attendanceDate,
          ids,
          status: 'rejected'
        }
        approvedOrRejected(obj).then((res) => {
          if (res && res.code === 200) {
            Message.success('驳回成功')
            this.queryApi()
          }
        })
      } else {
        Message.warning('请选择驳回项目')
      }
    },
    queryApi() {
      const obj = {
        ...this.formInline,
        pageNum: this.pageConfig.pageNum,
        pageSize: this.pageConfig.pageSize
      }
      obj.orgIds = obj.orgIds.toString()
      this.loading = true
      getHfmCodeList().then((res) => {
        if (res && res.code === 200) {
          if (res.data) {
            this.HFMList = res.data
          }
        }
      })
      staffInfoList(obj).then((res) => {
        this.loading = false
        if (res && res.code === 200) {
          if (res.data && res.data.list) {
            this.tableData = res.data.list
            this.pageConfig.total = res.data.total
          } else {
            this.tableData = []
            this.pageConfig.total = 0
          }
        }
      })
    },
    getDepartData() {
      const obj = {
        isDataRight: 0,
        status: 0
      }
      getTree(obj).then((res) => {
        if (res && res.code === 200) {
          if (res.data) {
            this.orgTreeData = res.data
          }
        }
      })
    },
    search() {
      this.queryApi()
    },
    cellClassName(row) {
      if (row.column.property === 'hfmCode') {
        return 'hoverLine'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin gen {
  position: relative;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.attendanceRecord {
  &__topInput {
    @include gen;
    &__title {
      display: flex;
      justify-content: space-between;
      margin: 20px 20px 0 20px;
      &__leftTitle {
        font-size: 21px;
        font-weight: 400;
        color: #303133;
      }
    }
    &__search {
      margin: 20px;
    }
    .el-form {
      .el-form-item {
        margin-bottom: 0 !important;
      }
    }
  }
  &__content {
    margin-top: 15px;
    //height: calc(100vh - 60px - 15px - 15px - 150px);
    @include gen;
    .poc-table {
      width: auto;
      margin: 20px;
      .poc-table-tip {
        .tipTitle {
          display: flex;
          justify-content: space-between;
          //margin-bottom: -6px;
          margin-bottom: 12px;
        }
        .el-checkbox {
          margin-right: 10px;
          .el-checkbox__label {
            font-size: 14px;
            //font-family: PingFangSC-Regular, PingFang SC, serif;
            font-weight: 400;
            color: #303133;
          }
        }
        .el-button {
          width: 68px;
          height: 28px;
          border-radius: 2px;
        }
      }
    }
  }
}
.green_box{
  padding: 2px 20px;
  background-color: rgba(110, 186, 175, .3);
  color: rgba(110, 186, 175, 1);
  font-weight: 400;
  display: inline-block;
  border-radius: 4px;
}
.orange_box{
  padding: 2px 20px;
  background-color: rgba(230, 162, 60, .3);
  color: rgba(230, 162, 60, 1);
  font-weight: 400;
  display: inline-block;
  border-radius: 4px;
}
.red_box{
  padding: 2px 20px;
  background-color: rgba(222, 57, 52, .3);
  color: rgba(222, 57, 52, 1);
  font-weight: 400;
  display: inline-block;
  border-radius: 4px;
}
.blue_box{
  padding: 2px 20px;
  background-color: rgba(64, 158, 255, .3);
  color: rgba(64, 158, 255, 1);
  font-weight: 400;
  display: inline-block;
  border-radius: 4px;
}
</style>
<style lang="scss">
::-webkit-scrollbar-corner {
  background: none !important;
} /* 横向滚动条和纵向滚*/
.hoverLine {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
