<template>
  <div class="attendanceRecordDetailDialog">
    <el-dialog
      title="考勤详情"
      :visible.sync="dialogVisible"
      width="420px"
      :before-close="handleClose"
    >
      <div class="attendanceRecordDetailDialog__header">
        <div>
          <span class="colorBg"></span><span class="colorText">1天</span>
        </div>
        <div>
          <span class="colorBg1"></span><span class="colorText">0.5天</span>
        </div>
        <div>
          <span class="colorBg2"></span><span class="colorText">假期日</span>
        </div>
      </div>
      <div class="attendanceRecordDetailDialog__content">
        <div class="attendanceRecordDetailDialog__content__month">
          {{ currentMonth }}
        </div>
        <div class="attendanceRecordDetailDialog__content__week">
          <div
            class="attendanceRecordDetailDialog__content__week__weekItem"
            v-for="item in weekList"
          >
            {{ item }}
          </div>
        </div>
        <div class="attendanceRecordDetailDialog__content__dayList">
          <div
            v-for="(item,index) in dayList"
            class="attendanceRecordDetailDialog__content__dayList__item"
            :key="index"
            :style="dayItemColor(item)"
          >
            {{ item.attendanceDate }}
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { attendanceDetail } from '@/api/attendance'

export default {
  name: 'attendanceRecordDetailDialog',
  data() {
    return {
      dialogVisible: false,
      dayList: [],
      attendanceDate:'',
      weekList: ['日', '一', '二', '三', '四', '五', '六']
    }
  },
  computed: {
    currentMonth() {
      return this.attendanceDate
    }
  },
  methods: {
    dayItemColor(val) {
      if (val.type) {
        if (val.type !== '1') {
          return `background: rgba(244, 244, 244);color: rgba(103, 105, 109, 1);`
        } else {
          if (val.days) {
            if (val.days === 0.5) {
              return `background: #E6A23C;color: white;`
            } else if (val.days === 1) {
              return `background: #6EBAAF;color: white;`
            }
          }
        }
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    formatDay(days) {
      days.forEach((item) => {
        if(item.attendanceDate){
          item.attendanceDate = this.$dayjs(item.attendanceDate).date()
        }
      })
    },
    show(val) {
      this.attendanceDate = val.date
      const obj = {
        attendanceDate: val.date,
        id: val.id
      }
      attendanceDetail(obj).then((res) => {
        if (res && res.code === 200) {
          if (res.data) {
            this.dayList = res.data || []
            if(this.dayList.length > 0){
              let weekNum = new Date(this.dayList[0].attendanceDate).getDay()
              for (let i = 0; i < weekNum ; i++) {
                this.dayList.unshift({attendanceDate:''})
              }
            }
            this.formatDay(this.dayList)
          }
        }
      })
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.attendanceRecordDetailDialog {
  ::v-deep.el-dialog {
    //height: 400px;
    .el-dialog__body {
      padding: 20px 20px 0 55px;
    }
  }
  &__header {
    display: flex;
    margin-bottom: 20px;
    border-bottom: 1px solid #ebeef5;
    padding-bottom: 20px;
    div {
      margin-right: 20px;
    }
    .colorText {
      display: inline-block;
    }
    .colorBg {
      display: inline-block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #6ebaaf;
      margin-right: 5px;
    }
    .colorBg1 {
      display: inline-block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #e6a23c;
      margin-right: 5px;
    }
    .colorBg2 {
      display: inline-block;
      margin-right: 5px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: rgba(244, 244, 244);
    }
  }
  &__content {
    &__week {
      display: flex;
      margin-top: 20px;
      &__weekItem {
        margin-right: 34px;
        margin-bottom: 17px;
        color: #67696d;
      }
    }
    &__month {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC, serif;
      font-weight: 500;
      color: #303133;
    }
    &__dayList {
      display: flex;
      flex-flow: wrap;
      &__item {
        color: #67696d;
        margin-right: 25px;
        margin-bottom: 15px;
        width: 22px;
        height: 23px;
        font-size: 14px;
        //font-family: PingFangSC-Regular, PingFang SC, serif;
        font-weight: 400;
        text-align: center;
        border-radius: 50%;
        line-height: 23px;
      }
    }
  }
}
</style>
